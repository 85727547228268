const React = require('react')

const GlobalContext = React.createContext({
  locale: '',
  location: {},
  siteConfig: {},
  breadcrumbData: {},
  // Allows to replace the last item of breadcrumb seo schema label.
  // Correspond to a useState define in Layout
  breadcrumbLastItemTitle: '',
  setBreadcrumbLastItemTitle: () => '',
  alternativeTheme: null,
  isDevServer: false,
})

module.exports = GlobalContext
