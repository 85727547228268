import styled, { css } from 'styled-components'
import { styleForTablet } from '@utils'
import { ChevronSVG } from '@icons'

export const IconPart = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    ${styleForTablet} {
      margin-right: ${theme.sizes.s7};
    }

    svg {
      height: ${theme.sizes.s8};
      width: ${theme.sizes.s8};
    }

    & * {
      fill: ${theme.colors.neutral0};
    }

    ${ChevronSVG} {
      height: ${theme.sizes.s13};
      width: ${theme.sizes.s13};
    }
  `}
`
