import styled, { css } from 'styled-components'
import { Container } from 'mailjet-react-components'

import ImageDiv from '@components/Image/styles/ImageDiv'
import { fadeInUpAnim } from '@styles/Animations'
import {
  gridHelper,
  styleForDesktop,
  styleForMobile,
  styleForTablet,
  styleForTabletAndMobile,
} from '@utils'

import DivText from './DivText'

const ParagraphWithAnyContainer = styled(Container)`
  ${({ theme }) => css`
    grid-row-gap: ${theme.sizes.s8};
    padding-bottom: 120px;
    padding-top: 120px;

    ${styleForTablet} {
      grid-row-gap: ${theme.sizes.s7};
      padding-bottom: ${theme.sizes.s15};
      padding-top: ${theme.sizes.s15};
    }

    ${styleForMobile} {
      grid-row-gap: ${theme.sizes.s6};
      padding-bottom: ${theme.sizes.s10};
      padding-top: ${theme.sizes.s10};
    }
  `}

  ${({ isAnimating, isTextTop, isTextRight, noAnimation, noRowGap, theme }) => {
    if (isTextTop) {
      return css`
        // Animations
        ${ImageDiv} {
          ${!noAnimation && fadeInUpAnim(isAnimating, 250)}
        }

        ${DivText} {
          ${!noAnimation && fadeInUpAnim(isAnimating)}
        }

        > :not(${ImageDiv}):not(${DivText}) {
          ${!noAnimation && fadeInUpAnim(isAnimating, 500)}
        }

        ${gridHelper(
          12,
          ['.', '.', 8, '.', '.'],
          ['.', '.', 8, '.', '.']
        )}
        ${noRowGap && `grid-row-gap: 0;`};

        ${styleForTablet} {
          ${gridHelper(6, [6], [6])}
          ${noRowGap && `grid-row-gap: 0;`};
        }

        ${styleForMobile} {
          ${gridHelper(4, [4], [4])}
          ${noRowGap && `grid-row-gap: 0;`};
        }
      `
    } else {
      return css`
        align-items: center;

        img {
          height: auto;
          width: 100%;
        }

        // Animations
        ${ImageDiv} {
          ${!noAnimation && fadeInUpAnim(isAnimating, !isTextRight && 250)}
        }

        ${DivText} {
          ${!noAnimation && fadeInUpAnim(isAnimating, isTextRight && 250)}
        }

        > :not(${ImageDiv}):not(${DivText}) {
          ${!noAnimation && fadeInUpAnim(isAnimating, 500)}
        }

        ${styleForDesktop} {
          ${isTextRight ? gridHelper(12, [7, 5]) : gridHelper(12, [5, 7])}

          ${DivText} {
            ${!isTextTop &&
              isTextRight ? `margin-left: ${theme.sizes.s10};`
              : `margin-right: ${theme.sizes.s10};`
            }
          }
        }

        ${styleForTabletAndMobile} {
          align-items: center;
          background-image: none;
          display: flex;
          flex-direction: ${isTextRight ? 'column' : 'column-reverse'};
          height: auto;
          width: auto;
        }
      `
    }
  }}
`
export default ParagraphWithAnyContainer
