/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react'
import '@contentful/live-preview/style.css'
import { filter, forEach } from 'lodash'
import { ThemePartialProvider } from 'mailjet-react-components'
import Layout from '@components/Layout'
import getTheme from '@theme'
import { getDomIdFromHash, scrollToNode } from '@utils'
import {
  findCurrentSiteName,
  sitesConfig,
} from '@utils/Gatsby-node/siteArchitectureHelpers.js'
import { setDomainCookie } from '@utils/setDomainCookie'

// The process.env is available just here, so we can't use findCurrentSiteConfig() for
// the theme
const siteName = findCurrentSiteName({
  GATSBY_MAILGUN_WEBSITE: process.env.GATSBY_MAILGUN_WEBSITE,
  GATSBY_MAILJET_WEBSITE: process.env.GATSBY_MAILJET_WEBSITE,
  GATSBY_SINCH_WEBSITE: process.env.GATSBY_SINCH_WEBSITE,
})
const siteConfig = sitesConfig[siteName]

export const wrapRootElement = ({ element }) => (
  <ThemePartialProvider theme={getTheme(siteConfig)}>
    {element}
  </ThemePartialProvider>
)

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props
  // Menu is not provided for preview template at the moment
  return pageContext.noLayout || Object.keys(pageContext).length === 0 ? (
    element
  ) : (
    <Layout {...props}>{element}</Layout>
  )
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash) {
    const node = document.getElementById(getDomIdFromHash(location.hash))
    // if hash specified and corresponding node is existing, use the scrollToNode helper
    if (node) {
      scrollToNode(node, 58) //  58px is 2px more than navbar height (56px)
    } else {
      // else reset the position at top of page
      window.scrollTo(0, 0)
    }
    return false
  }
}

export const onClientEntry = (rest) => {
  const { ___loader: loader } = window

  if (!loader) {
    return
  }
  // Original loadPage functions, should be returned from the custom ones
  const originalLoadPage = loader.loadPage

  loader.loadPage = async (rawPath) => {
    // Delay the current page call, avoiding reloading the page and fixing the document.referrer issue
    if (rawPath === window.location.pathname) {
      // Todo: might check if can be replaced by loadPageSync instead of "randomly" debouncing the original loadPage
      return new Promise((resolve) =>
        setTimeout(() => resolve(originalLoadPage(rawPath)), 1000),
      )
    } else {
      return originalLoadPage(rawPath)
    }
  }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Rudderstack tracking from gatsby-plugin-rudderstack is not handling cookie consent.
  // The following code is adapted from the plugin gatsby-browser implementation.
  const trackRudderStackPage = () => {
    // Adding a delay ensuring that the RudderStack route tracking is in sync with the actual Gatsby route
    // (otherwise you can end up in a state where the RudderStack page tracking reports
    // the previous page on route change).
    window.setTimeout(function () {
      window.rudderanalytics && window.rudderanalytics.page()
    }, 50)
  }

  // RudderStack to track page views depending on consent, if navigation was internal
  if (window?.OnetrustActiveGroups?.includes('2,') && prevLocation) {
    trackRudderStackPage()
  }

  // Manually trigger page view for GTM layer
  const page_path = location
    ? location.pathname + location.search + location.hash
    : undefined
  // Waiting to ensure the title has properly been changed
  setTimeout(() => {
    const data = window.dataLayer
    if (data) {
      data.push({
        event: 'pageview',
        page: {
          path: page_path,
        },
      })
    }
  }, 100)
  // Look the location parameter and set cookie for every parameter of the list cookiesFieldTable found
  if (location?.search) {
    const cookiesFieldTable = [
      'utm_source=',
      'utm_medium=',
      'utm_term=',
      'utm_content=',
      'utm_campaign=',
      'aff_id=',
      'transid=',
      'gclid=',
      'gspk=',
      'gsxid=',
      'pscd=',
    ]
    const searchParameters = location?.search
    const parametersFound = filter(cookiesFieldTable, (cookie) =>
      searchParameters.includes(cookie),
    )

    if (parametersFound.length > 0) {
      forEach(parametersFound, (parameter) => {
        const valueParameter = searchParameters
          .split(parameter)[1]
          .split('&')[0]
        setDomainCookie(parameter.slice(0, -1), valueParameter)
      })
    }
  }
}
