import { css } from 'styled-components'
import { Body2Bold, Body3Bold } from '@typography'

export const injectSecondaryButtonDarkStyle = ({ theme }) => css`
  &:after {
    border-color: transparent;
  }

  &:hover:not(.is-loading):not(.is-disabled):not(:active) {
    background-color: ${theme.colors.neutral100}33;

    &:before {
      border-color: transparent;
      border-radius: ${theme.globals.borderRadius.bR1};
    }
  }

  &:active:not(.is-disabled):not(.is-loading) {
    background-color: ${theme.colors.neutral100}66;
  }

  &:focus:not(:active):not(.is-loading) {
    background-color: ${theme.colors.neutral100}33;

    &:before {
      border-color: transparent;
    }
  }

  &.is-loading > * {
    border-color: ${theme.colors.neutral0};
    border-top-color: transparent;
  }

  & > ${Body2Bold}, & > ${Body3Bold} {
    color: ${theme.colors.neutral0};
  }
`
