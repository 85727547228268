import { useEffect, useState } from 'react'

const useWindowWidth = (isUsingDocumentWidth = false) => {
  const [windowWidth, setWindowWidth] = useState(undefined)
  const getWindowWidth = () => isUsingDocumentWidth ? document?.body?.clientWidth : window.innerWidth

  useEffect(() => {
    if (windowWidth === undefined) {
      setWindowWidth(getWindowWidth())
    }
    function handleResize() {
      setWindowWidth(getWindowWidth())
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [windowWidth])

  return windowWidth
}

export default useWindowWidth
