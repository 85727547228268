import React from 'react'
import styled, { css } from 'styled-components'
import { withLink } from './withLink'
import { ButtonPrimary } from './index'

const StyledButtonPrimary = styled(ButtonPrimary)`
  ${({ theme }) => css`
    border-radius: ${theme.sizes.s3};
    height: ${theme.sizes.s8};
    width: ${theme.sizes.s8};
    padding: ${theme.sizes.s12};

    background: ${theme.colors.primary50};
    border: ${theme.sizes.s1} solid ${theme.colors.primary50};
    box-shadow: 0 ${theme.sizes.s1} ${theme.sizes.s3} -${theme.sizes.s1} ${theme.colors.primary50}66;

    &.is-disabled {
      background: ${theme.colors.primary50};
      border-color: ${theme.colors.primary50};
      opacity: 0.4;
    }

    p {
      display: flex;
      align-items: center;
    }

    svg {
      height: ${theme.sizes.s13};
      width: ${theme.sizes.s13};
    }

    svg * {
      fill: ${theme.colors.iconPrimary};
    }
  `}
`
const ButtonIcon = ({ children, icon, small, to, ...props }) => (
  <StyledButtonPrimary
    aria-label={children && children.toString()}
    mode='primary'
    small={small}
    {...props}
  >
    {icon}
  </StyledButtonPrimary>
)

export default styled(withLink(ButtonIcon))``
