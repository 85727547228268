// File used for theme build
import { merge } from 'lodash'
import { mailjet } from 'mailjet-react-components'

import getAccordion from './Accordion'
import Breakpoints from './Breakpoints'
import getButton from './Button'
import getCheckbox from './Checkbox'
import getCodeEditor from './CodeEditor'
import getColors from './Colors'
import getFonts from './Fonts'
import getSizes from './Sizes'
import getSlider from './Slider'
import getTextArea from './Textarea'
import getTypography from './Typography'

export const baseShadeSecondary = (siteName) =>
  siteName === 'Mailgun' || siteName === 'Inbox Ready' ? 80 : 70

const getTheme = (siteConf) => {
  const colors = getColors(siteConf)
  const fonts = getFonts(siteConf)
  const sizes = getSizes()
  const currentSite = siteConf.name

  return merge({}, mailjet, {
    colors,
    // Overriding component factory function to include our custom typography config
    componentsFactory: (params) =>
      merge(
        {},
        { ...mailjet.componentsFactory(params) },
        { accordion: getAccordion(colors) },
        {
          button: getButton({
            colors,
            baseShadePrimary: 50,
            baseShadeSecondary: baseShadeSecondary(currentSite),
          }),
        },
        { checkbox: getCheckbox(colors) },
        { codeEditor: getCodeEditor(colors) },
        { slider: getSlider(colors) },
        { textArea: getTextArea(colors) },
        { typography: getTypography(colors) },
        // TODO : See to add a value for menu height for every device/breakpoint
      ),
    fonts,
    responsive: merge(mailjet.responsive, Breakpoints),
    sizes,
  })
}

export default getTheme
