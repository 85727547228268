// TODO: see to improve columns types
const paragraphTypeList = {
  carouselQuote: 'Carousel Quote',
  sectionAccordionLeft: 'Section Accordion Left',
  sectionAccordionRight: 'Section Accordion Right',
  sectionCards: 'Section Cards',
  sectionCardsIconCarousel: 'Section Cards Icon Carousel',
  sectionCodeEditorLeft: 'Section Code Editor Left',
  sectionCodeEditorRight: 'Section Code Editor Right',
  sectionColumnsCenterData: 'Section Columns Center Data',
  sectionColumnsCenterIcon: 'Section Columns Center Icon',
  sectionColumnsLeftData: 'Section Columns Left Data',
  sectionColumnsLeftIcon: 'Section Columns Left Icon',
  sectionColumnsRightData: 'Section Columns Right Data',
  sectionColumnsRightIcon: 'Section Columns Right Icon',
  sectionData: 'Section Data',
  sectionFAQ: 'Section FAQ',
  sectionForm: 'Section Form',
  sectionFormCarousel: 'Section Form Carousel',
  sectionFormWithoutText: 'Section Form Without Text',
  sectionHeaderCenter: 'Section Header Center',
  sectionHeaderFormLeft: 'Section Header Form Left',
  sectionHeaderFormRight: 'Section Header Form Right',
  sectionHeaderLeft: 'Section Header Left',
  sectionHeaderRight: 'Section Header Right',
  sectionReadMore: 'Section Read More',
  sectionSignup: 'Section Signup',
  sectionTableComparison: 'Section Table Comparison',
  sectionTeam: 'Section Team',
  sectionTextImageCenter: 'Section Text Image Center',
  sectionTextImageLeft: 'Section Text Image Left',
  sectionTextImageRight: 'Section Text Image Right',
  table: 'Table',
}

const getParagraphWithAnySectionTypeList = () => paragraphTypeList

module.exports = {
  getParagraphWithAnySectionTypeList,
  paragraphTypeList,
}
