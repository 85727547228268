import React, { useContext } from 'react'
import GlobalContext from '@components/Layout/GlobalContext'
import { StyledLink, ExternalLink } from '@styles/StyledLink'
import { sanitizeTrailingSlash } from '@utils'
import NavigationContext from '@components/Navigation/NavigationContext'
import trackClick from '@utils/GA4/trackClick'

// to capitalize every word in a sentence
const capitalizeEachWord = (labelText) =>
  !labelText
    ? ''
    : labelText
        .replace(/\b(\w)/g, (char) => char.toUpperCase())
        .replace(/-\b(\w)/g, '-$1')

export const withLink =
  (Component) =>
  ({ fileName, onClick, to, ...props }) => {
    const { icon, isNotCapitalized } = props
    let { children } = props
    const { locale } = useContext(GlobalContext)
    const isEnglish = locale.split('-')[1] === 'US'
    if (children && typeof children === 'string') {
      // capitalize only first word if isNotCapitalized, capitalize each word otherwise
      children = !isEnglish
        ? children.toString()
        : isNotCapitalized
        ? children.toString().split(' ')[0].charAt(0).toUpperCase() +
          children.toString().slice(1).toLowerCase()
        : capitalizeEachWord(children.toString())
    }
    const { isNavigationChild } = useContext(NavigationContext)
    const { isAllowingPerformanceScript } = useContext(GlobalContext)
    // Track CTA click
    const trackClickCTA = (e) => {
      // Avoid tracking navbar CTA click
      if (isNavigationChild) return
      // Still run original onClick if provided
      if (onClick) onClick(e)
      // As for now, the only case this should not run is on button icon
      if (!icon && isAllowingPerformanceScript) {
        trackClick({
          fileName,
          text: children,
          to,
        })
      }
    }

    props = { ...props, onClick: trackClickCTA }

    if (!to) {
      return <Component {...props} />
    }

    return (
      // Link is internal if first char is '/' or '#'
      ['/', '#'].includes(to[0]) ? (
        <StyledLink to={sanitizeTrailingSlash(to)}>
          <Component {...props} />
        </StyledLink>
      ) : (
        <ExternalLink href={to}>
          <Component {...props} />
        </ExternalLink>
      )
    )
  }
