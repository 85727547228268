import styled, { css } from 'styled-components'
import { Div } from 'mailjet-react-components'

import { Background } from './Background'

export const SectionWithBackgroundColor = styled(Div)`
  ${({
    backgroundColor,
    bottomPaddingExtended,
    noBackgroundImage,
    theme,
    topPaddingExtended,
  }) => css`
    background-color: ${theme.colors[backgroundColor] ||
    backgroundColor ||
    theme.colors.neutral0};
    // Remove border-radius 3px put by default by mailjet-react-component Div
    border-radius: 0;
    position: relative;

    ${bottomPaddingExtended &&
    css`
      overflow: visible;
      padding-bottom: ${theme.sizes.s10};
    `}

    ${topPaddingExtended &&
    css`
      padding-top: ${theme.sizes.s10};
    `}
    
    // allows you to disable z-index on all element page.
    ${!noBackgroundImage &&
    css`
      & * {
        z-index: 1000;
      }
      & > ${Background} {
        z-index: 0;
      }
    `}
  `}
`
