import React from 'react'
import { get } from 'lodash'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import Image from '@components/Image'
import { ExternalLink, StyledLink } from '@styles'
import ImageInline from '@styles/ImageInline'
import {
  Body1,
  Header1,
  Header2,
  Header3,
  Header4,
  Header5Bold,
  Header6,
} from '@typography'
import { imageFormatter } from '@utils/Preview'
import VideoPlayer from '@components/VideoPlayer'

const getOptionRichText = (
  customRenderer = null,
  imageProps = { desktopMaxWidth: '700px' },
) => {
  const embeddedContentToReact = (node) => {
    /* FYI: elementProps is built from a request inside gatsby-node in order to gather all data 
    related to an image that belong to a rich text. */
    const { nodeType } = node
    // Use custom rich text renderer for anything that is not an image/asset
    if (
      customRenderer &&
      nodeType !== BLOCKS.EMBEDDED_ASSET &&
      nodeType !== INLINES.EMBEDDED_ENTRY
    ) {
      return customRenderer(node)
    } else {
      const desktopMaxWidth = imageProps.desktopMaxWidth.replace('px', '')
      const getDisplayWidth = (originalWidth, desktopMaxWidth) =>
        originalWidth < desktopMaxWidth && originalWidth + 'px'

      const videoUrl = node?.data?.target?.picture?.file?.url || undefined
      const isWebMFile = (videoUrl && videoUrl.includes('.webm')) || undefined
      // WebM video file
      if (isWebMFile) {
        return (
          <VideoPlayer
            videoProps={{
              url: videoUrl,
              controls: false,
              loop: true,
              muted: true,
              isAutoPlay: true,
              isWebm: true,
            }}
          />
        )
      }

      if (get(node, 'data.target.picture')) {
        node.data.target.file = node.data.target.picture.file
        node.data.target.gatsbyImageData =
          node.data.target.picture.gatsbyImageData
      }

      const { target } = node?.data || {}
      // Contentful Asset image
      if (nodeType === BLOCKS.EMBEDDED_ASSET) {
        const originalWidth = get(node, 'data.target.file.details.image.width')
        const displayWidth = getDisplayWidth(originalWidth, desktopMaxWidth)
        imageProps = { ...imageProps, displayWidth }

        return <Image {...target} {...imageProps} />
      }
      // Contentful Image Entry
      if (nodeType === INLINES.EMBEDDED_ENTRY) {
        // If Preview mode is used format is different, TODO: see if there is a more convenient solution
        const picture = target?.fields?.picture
          ? imageFormatter(node.data.target.fields.picture)
          : target

        const originalWidth = picture?.file?.details?.image?.width
        const displayWidth = getDisplayWidth(originalWidth, desktopMaxWidth)
        imageProps = { ...imageProps, displayWidth }

        return (
          <ImageInline
            alt={get(node, 'data.target.alternative')}
            {...picture}
            {...imageProps}
          />
        )
      }
    }
  }

  return {
    renderNode: {
      // Link display either a Gatsby link if the link is internal or a simple a tag if external
      [INLINES.HYPERLINK]: (node, value) =>
        node.data.uri.slice(0, 1) === '/' ? (
          <StyledLink
            isDisplayedInline={false}
            underline
            isParagraphLink
            to={node.data.uri}
          >
            {value}
          </StyledLink>
        ) : (
          <ExternalLink
            isDisplayedInline={false}
            underline
            isParagraphLink
            href={node.data.uri}
          >
            {value}
          </ExternalLink>
        ),
      [BLOCKS.UL_LIST]: (_node, children) => <ul>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (_node, children) => <li>{children}</li>,
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <Body1 key={children}>{children}</Body1>
      ),
      [BLOCKS.HEADING_1]: (_node, children) => (
        <Header1 as='h2'>{children}</Header1>
      ),
      [BLOCKS.HEADING_2]: (_node, children) => (
        <Header2 as='h2'>{children}</Header2>
      ),
      [BLOCKS.HEADING_3]: (_node, children) => (
        <Header3 as='h3'>{children}</Header3>
      ),
      [BLOCKS.HEADING_4]: (_node, children) => (
        <Header4 as='h4'>{children}</Header4>
      ),
      [BLOCKS.HEADING_5]: (_node, children) => (
        <Header5Bold as='h5'>{children}</Header5Bold>
      ),
      [BLOCKS.HEADING_6]: (_node, children) => (
        <Header6 as='h6'>{children}</Header6>
      ),
      [INLINES.EMBEDDED_ENTRY]: embeddedContentToReact,
      [BLOCKS.EMBEDDED_ASSET]: embeddedContentToReact,
      [BLOCKS.EMBEDDED_ENTRY]: embeddedContentToReact,
    },
  }
}

export default getOptionRichText
