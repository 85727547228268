import React from 'react'

const XSVG = () => (
  <svg
    width='24px'
    height='24px'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 40H12L21.3673 28.898L29 40H40L27.4286 21.7143L39 8H34L25.1837 18.449L18 8H7L19.1224 25.6327L7 40ZM16.4219 11H12.7031L30.5781 37H34.2969L16.4219 11Z'
      fill='#8796A0'
    />
  </svg>
)

export default XSVG
