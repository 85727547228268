import React from 'react'

const LinkedInSVG = () => (
  <svg
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <title>LinkedIn</title>
    <g
      id='Research-Report-Ebooks-Guides'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g id='Guide-Desktop' transform='translate(-1220.000000, -1468.000000)'>
        <g id='Social-Media' transform='translate(1208.000000, 1344.000000)'>
          <g id='LinkedIn' transform='translate(0.000000, 112.000000)'>
            <g id='LinkedIn-Icon' transform='translate(12.000000, 12.000000)'>
              <rect id='Rectangle' x='0' y='0' width='24' height='24'></rect>
              <path
                d='M22,14.2619841 L22,22 L17.7133524,22 L17.7133524,14.7802298 C17.7133524,12.9665646 17.0933351,11.7288091 15.5417107,11.7288091 C14.3572423,11.7288091 13.6523644,12.5629656 13.3422627,13.3698717 C13.2291772,13.6583374 13.2000688,14.059844 13.2000688,14.4636376 L13.2000688,22 L8.91197976,22 C8.91197976,22 8.96973149,9.77207897 8.91197976,8.50517516 L13.1996503,8.50517516 L13.1996503,10.4180126 C13.190955,10.4323191 13.1796558,10.4477448 13.1714719,10.4616133 L13.1996503,10.4616133 L13.1996503,10.4180126 C13.7693093,9.49981752 14.7866604,8.18814507 17.0638082,8.18814507 C19.8850406,8.18814507 22,10.1168948 22,14.2619841 Z M4.42645606,2 C2.95950637,2 2,3.00695374 2,4.33113221 C2,5.62645437 2.93174648,6.66396757 4.36954131,6.66396757 L4.39818468,6.66396757 C5.89354524,6.66396757 6.82352476,5.62664902 6.82352476,4.33113221 C6.79534638,3.00695374 5.89354524,2 4.42645606,2 Z M2.25467489,22 L6.54113648,22 L6.54113648,8.50517516 L2.25467489,8.50517516 L2.25467489,22 Z'
                id='LinkedIn'
                fill='#8796A0'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default LinkedInSVG
