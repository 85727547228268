import styled, { css } from 'styled-components'
import { styleForTablet, styleForMobile } from '@utils'

export const RoundedColorBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary10};
    border-radius: ${theme.sizes.s4};
    padding: ${theme.sizes.s10};
    box-shadow: 0 ${theme.sizes.s5} ${theme.sizes.s10} -${theme.sizes.s6} ${theme.colors.primary50}29;

    ${styleForTablet} {
      padding: ${theme.sizes.s8};
    }

    ${styleForMobile} {
      padding: ${theme.sizes.s6} ${theme.sizes.s5};
    }
  `}
`
