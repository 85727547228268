import styled, { css } from 'styled-components'
import { styleForMobile } from '@utils'
import CheckSVG from '@icons/CheckSVG'

const NewsletterForm = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.sizes.s3};

    .grecaptcha-badge {
      visibility: hidden;
      left: 0;
    }

    ${styleForMobile} {
      padding-bottom: ${theme.sizes.s6};
    }

    button {
      height: ${theme.sizes.s7};
      width: ${theme.sizes.s7};
      min-height: ${theme.sizes.s7};
      max-width: ${theme.sizes.s7};
      min-width: ${theme.sizes.s7};
      padding: 0;

      p,
      svg {
        height: ${theme.sizes.s13};
        width: ${theme.sizes.s13};
        font-size: 0;
        * {
          fill: ${theme.colors.neutral0};
        }

        ${CheckSVG} * {
          stroke: ${theme.colors.neutral0};
          fill: transparent;
        }
      }

      svg {
        transition:
          opacity 500ms,
          transform 700ms;

        &.enter {
          opacity: 1;
        }

        &.enter-done {
          transform: scale(1);
          opacity: 1;
        }
      }

      svg.enter-active {
        transform: scale(1.2);
        opacity: 0;
      }
    }
  `}
`

export default NewsletterForm
