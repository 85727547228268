import styled, { css } from 'styled-components'

import { Body1, Caption2 } from '@typography'
import { styleForMobile } from '@utils'

const DivText = styled.div`
  ${({ theme }) => css`
    text-align: left;

    ul {
      margin: ${theme.sizes.s5} 0 0;

      ${styleForMobile} {
        margin: ${theme.sizes.s4} 0 0;
      }
    }

    //Specifying li::marker color for getting darkmode conversion on bullet point
    ul li::marker {
      color: ${theme.colors.neutral70};
    }

    & > a {
      margin-top: ${theme.sizes.s7};

      ${styleForMobile} {
        margin-top: ${theme.sizes.s6};
      }
    }

    a:not(:last-child) {
      button {
        margin-right: ${theme.sizes.s4};
      }
    }

    b {
      color: ${theme.colors.neutral90};
    }

    & > ${Body1} {
      margin-top: ${theme.sizes.s5};

      ${styleForMobile} {
        margin-top: ${theme.sizes.s4};
      }
    }

    ${Caption2} {
      margin-bottom: ${theme.sizes.s3};
    }    
  `}
`

export default DivText
