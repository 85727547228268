import styled from 'styled-components'
import { Container } from 'mailjet-react-components'
import { BannerContainer } from '@components/Banner/styles/BannerContainer'
import { styleForTablet, styleForMobile } from '@utils'

// In order to display the content between two section, this should be displayed in the top one's container.
export const BetweenSectionContainer = styled(Container)`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 0;
  max-width: 1024px;
  order: 3;
  padding: 0;
  position: relative;
  width: 100%;

  & > :first-child {
    position: absolute;
    z-index: 1000;
  }

  ${BannerContainer} {
    ${styleForTablet} {
      margin: 0 ${({ theme }) => theme.sizes.s5};
    }

    ${styleForMobile} {
      margin: 0 ${({ theme }) => theme.sizes.s4};
    }
  }
`
