import styled, { css } from 'styled-components'
import { Caption1 } from '@typography'
import { styleForMobile, styleForTablet } from '@utils'

import { IconPart } from './IconPart'
import { TextPart } from './TextPart'

const getColorProperties = (themecolor, theme, shadeBackground = 10) => {
  // Force the background color to primary10 on darkmode
  const backgroundColor =
    themecolor === 'primary'
      ? shadeBackground === 10 ? theme.colors.surfaceBanner : theme.colors.surfaceBannerHover
      : theme.colors[themecolor + shadeBackground]
  const shadowColor = theme.colors[themecolor + 50] + '29'

  return `
    background-color: ${backgroundColor};
    box-shadow: 0 ${theme.sizes.s5} ${theme.sizes.s15} -${theme.sizes.s7} ${shadowColor};
  `
}

export const BannerContainer = styled.div`
  ${({ theme, enableHover, themecolor = 'primary' }) => css`
    ${getColorProperties(themecolor, theme)};

    align-items: center;
    justify-content: center;
    border-radius: ${theme.sizes.s4};
    display: flex;
    column-gap: ${theme.sizes.s8};
    padding: ${theme.sizes.s8} ${theme.sizes.s15};
    transition: 0.15s;

    ${!enableHover &&
    `&:hover {
        ${getColorProperties(themecolor, theme, 20)};
        cursor: pointer;
        transition: 0.15s;
      }`}

    ${TextPart} {
      flex-grow: 1;
    }

    ${styleForTablet} {
      padding: ${theme.sizes.s8} ${theme.sizes.s9};
    }

    ${styleForMobile} {
      justify-content: space-around;
      max-width: 100%;
      padding: ${theme.sizes.s6} ${theme.sizes.s5};
      & > div:first-child {
        display: none;
      }
    }

    ${IconPart} svg * {
      fill: ${theme.colors.primary40};
      stroke: ${theme.colors.primary40};
    }

    // Animation are using class injected by CSSTransition
    ${IconPart}, ${Caption1} {
      transition:
        opacity 500ms,
        transform 700ms;

      &.enter {
        opacity: 1;
      }
      &.enter-done {
        transform: scale(1);
        opacity: 1;
      }
    }
    ${IconPart}.enter-active {
      transform: scale(1.2);
      opacity: 0;
    }

    ${Caption1}.enter-active {
      transform: translateY(-${theme.sizes.s3});
      opacity: 0;
    }
    .grecaptcha-badge {
      visibility: hidden;
      right: 0;
    }
  `}
`
