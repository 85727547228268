import React from 'react'

const FacebookSVG = () => (
  <svg
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <title>Facebook</title>
    <g
      id='Research-Report-Ebooks-Guides'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g id='Guide-Desktop' transform='translate(-1220.000000, -1356.000000)'>
        <g id='Social-Media' transform='translate(1208.000000, 1344.000000)'>
          <g id='Facebook-Icon' transform='translate(12.000000, 12.000000)'>
            <rect id='Rectangle' x='0' y='0' width='24' height='24'></rect>
            <path
              d='M17.7089938,1.00457747 L14.740794,1 C11.4061234,1 9.25111268,3.12508973 9.25111268,6.41422968 L9.25111268,8.91055211 L6.26672054,8.91055211 C6.00883363,8.91055211 5.8,9.11150297 5.8,9.35937289 L5.8,12.9762596 C5.8,13.2241295 6.00907175,13.4248515 6.26672054,13.4248515 L9.25111268,13.4248515 L9.25111268,22.5514081 C9.25111268,22.799278 9.45994631,23 9.71783323,23 L13.6116161,23 C13.869503,23 14.0783366,22.7990491 14.0783366,22.5514081 L14.0783366,13.4248515 L17.567787,13.4248515 C17.825674,13.4248515 18.0345076,13.2241295 18.0345076,12.9762596 L18.0359363,9.35937289 C18.0359363,9.24035871 17.9866449,9.12637974 17.8992539,9.04215432 C17.8118628,8.9579289 17.6928015,8.91055211 17.5689777,8.91055211 L14.0783366,8.91055211 L14.0783366,6.79438844 C14.0783366,5.77727495 14.3305086,5.26093651 15.709001,5.26093651 L17.7085176,5.26024989 C17.9661664,5.26024989 18.175,5.05929902 18.175,4.81165798 L18.175,1.45316938 C18.175,1.20575721 17.9664045,1.00503522 17.7089938,1.00457747 Z'
              id='Shape'
              fill='#8796A0'
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default FacebookSVG
