import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Image from '@components/Image'

import { StyledParagraphWithAnySection } from './styles'

const SectionTextImage = ({ children, image, isTextTop = false, ...rest }) => (
  <StyledParagraphWithAnySection isTextTop={isTextTop} {...rest}>
    {/* Display a grey place holder if no image provided */}
    {image && !isTextTop && <Image alt={image.alternative} {...image.picture} />}
    {children}
  </StyledParagraphWithAnySection>
)

SectionTextImage.propTypes = {
  children: PropTypes.object,
  image: PropTypes.object,
}

export default styled(SectionTextImage)``
