const { filter, find } = require('lodash')

const CONTENT_TYPES = {
  ASSET: 'ContentfulAsset',
  BLOG_ARTICLE: 'ContentfulBlogArticle',
  BUTTON: 'ContentfulButton',
  CARD: 'ContentfulCard',
  CASE_STUDY_ARTICLE: 'ContentfulCaseStudyArticle',
  CATEGORY: 'ContentfulCategory',
  CODE_SNIPPET: 'ContentfulCodeSnippet',
  CODE_EDITOR: 'ContentfulCodeEditor',
  DROPDOWN: 'ContentfulDropDown',
  EBOOK_ARTICLE: 'ContentfulEbookArticle',
  FORM: 'ContentfulForm',
  GUIDE_ARTICLE: 'ContentfulGuideArticle',
  IMAGE: 'ContentfulImage',
  INPUT: 'ContentfulInput',
  INTEGRATION_ARTICLE: 'ContentfulIntegrationArticle',
  JSON_OBJECT: 'ContentfulJsonObject',
  LOCALIZED_TABLE_WITH_TITLE: 'ContentfulLocalizedTableWithTitle',
  MEDIA: 'ContentfulMedia',
  MEDIA_ARTICLE: 'ContentfulMediaArticle',
  MENU: 'ContentfulMenu',
  PAGE: 'ContentfulPage',
  PAGE_CONTENT: 'ContentfulPageContent',
  PARAGRAPH: 'ContentfulParagraph',
  PARAGRAPH_WITH_ANY_SECTION: 'ContentfulParagraphWithAnySection',
  PARAGRAPH_WITH_TITLE: 'ContentfulParagraphWithTitle',
  PARAGRAPH_WITH_TITLE_BUTTON: 'ContentfulParagraphWithTitleCaptionButton',
  PARTNER_ARTICLE: 'ContentfulPartnerArticleV2',
  SECTION_QUOTE: 'ContentfulSectionQuote',
  SECTION: 'ContentfulSection',
  SEO_CONFIG: 'ContentfulSeoConfig',
  SET_OF_THREE: 'ContentfulSetOfThree',
  SET_OF_TWO: 'ContentfulSetOfTwo',
  SITE_ARCHITECTURE: 'ContentfulSiteArchitecture',
  TABLE: 'ContentfulTable',
  TAG: 'ContentfulTag',
  TACTIC: 'ContentfulTactic',
  searchFor: {
    TABLE: /Table/g,
  },
}

const filterByContentType = (data, contentType) =>
  filter(data, (el) => el.__typename === contentType)

const findByContentType = (data, contentType) =>
  find(data, (el) => el.__typename === contentType)

module.exports = {
  CONTENT_TYPES,
  filterByContentType,
  findByContentType,
}
