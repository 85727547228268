// Adapt preview data to regular Contentful plugin format
import { compact, concat, map } from 'lodash'

export const imageFormatter = (entry) => {
  const {
    fields: {
      file: {
        details: {
          image: { height, width },
        },
        url,
      },
    },
    sys: { id: contentful_id },
  } = entry

  const formatedUrl = 'https:' + url
  const aspectRatio = width / height
  return {
    ...entry,
    contentful_id,
    __typename: 'ContentfulAsset',
    gatsbyImageData: {
      width,
      height,
      images: {
        sources: [
          {
            srcSet: concat(
              map(
                [584, 533, 684],
                (width) =>
                  formatedUrl +
                  `?fm=webp&w=${width}&h=${Math.floor(
                    width / aspectRatio,
                  )}&q=50 ${width}w,\n`,
              ),
            ).join(' '),
            type: 'image/webp',
          },
        ],
        fallback: {
          src: formatedUrl + `?w=${width}&h=${height}&q=50&fm=png`,
          srcSet: concat(
            map(
              [584, 533, 684],
              (width) =>
                formatedUrl +
                `?w=${width}&h=${Math.floor(
                  width / aspectRatio,
                )}&q=50 ${width}w,\n`,
            ),
          ).join(' '),
        },
      },
    },
  }
}
// Adapt the rich text data from ContentAPI to the contentful-source-plugin
// Try to reverse that and pass it in the template directly so we don't need to use SSR
//   test first hardcoding same object as parameter for the template
export const sanitizePreviewRichText = (text) => ({
  raw: JSON.stringify({
    ...text,
    // Editing ref directly, not using lodash otherwise it would make the live update break
    content: text.content.map((entry) => {
      const { nodeType } = entry
      // Todo: support blocks, see if there is a way to convert everything in Contentful lib
      return nodeType === 'embedded-asset-block' ||
        nodeType === 'embedded-entry-inline'
        ? {
            nodeType,
            data: {
              target: {
                sys: {
                  id: entry?.data?.target?.sys?.id,
                  type: 'Link',
                  linkType: 'Asset',
                },
              },
            },
            content: [],
          }
        : entry
    }),
  }),
  references: compact(
    map(text.content, (entry) => {
      const { nodeType } = entry
      if (
        nodeType === 'embedded-asset-block' ||
        nodeType === 'embedded-entry-inline'
      )
        return imageFormatter(entry.data.target)
      // TODO: support embbeded blocks
      return null
    }),
  ),
})
