import MGIcon from '../../../static/svg/MailgunIcon.svg'
import MJIcon from '../../../static/svg/MailjetIcon.svg'

const mailgunEmails = [
  'deliv-team@mailgun.company',
  'outbound-sales@mailgun.company',
  'spamtrapmonitoring@mailgun.company',
  'iceman@mailgun.top',
  'admiral@mailgun.top',
  'timduncan@mailgun.top',
]

const mailjetEmails = [
  'deliv-team@mailjet.company',
  'outbound-sales@mailjet.company',
  'spamtrapmonitoring@mailjet.company',
  'pop@mailjet.top',
  'manu@mailjet.top',
  'tonyparker@mailjet.top',
]

const spamTrap = {
  Mailgun: mailgunEmails,
  Mailjet: mailjetEmails,
}

const svgIcons = {
  Mailjet: MJIcon,
  Mailgun: MGIcon,
}

const logos = {
  Mailjet:
    'https://images.ctfassets.net/y6oq7udscnj8/34r8FUOtvd8lXdEDteTxSG/b38a5e52f36a4e5835d325bec79c4ddf/MJ-Icon.png',
  Mailgun:
    'https://images.ctfassets.net/y6oq7udscnj8/6bfhvqjWqiC8dCzBNHxtJP/d682492374473b2e0d1377f0d4247bda/MG-Icon.png',
}

const seoSchemas = {
  mailgun: {
    'en-US': {
      sameAs: [
        'https://www.facebook.com/mailgun/',
        'https://twitter.com/Mail_Gun/',
        'https://www.linkedin.com/company/mailgun-inc/',
      ],
      url: `https://www.mailgun.com/`,
    },
  },
  mailjet: {
    'en-US': {
      sameAs: [
        'https://www.facebook.com/mailjet',
        'https://twitter.com/mailjet/',
        'https://www.linkedin.com/company/mailjet/',
      ],
      url: 'https://www.mailjet.com/',
    },
    'fr-FR': {
      sameAs: [
        'https://www.facebook.com/MailjetFrance/',
        'https://twitter.com/mailjet_fr/',
        'https://www.linkedin.com/company/mailjet/',
      ],
      url: 'https://www.mailjet.com/fr/',
    },
    'de-DE': {
      sameAs: [
        'https://www.facebook.com/MailjetDE',
        'https://twitter.com/mailjet_de/',
        'https://www.linkedin.com/company/mailjet/',
      ],
      url: 'https://www.mailjet.com/de/',
    },
    'es-ES': {
      sameAs: [
        'https://www.facebook.com/mailjetespanol/',
        'https://twitter.com/mailjet_es/',
        'https://www.linkedin.com/company/mailjet/',
      ],
      url: 'https://www.mailjet.com/es/',
    },
  },
}

const getSeoSchema = (brandName = 'Mailjet', locale) =>
  JSON.stringify({
    // Only mailjet has located schema
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: brandName,
        logo: logos[brandName],
        legalName: brandName,
        ...seoSchemas[brandName?.toLowerCase()]?.[
          brandName === 'Mailjet' ? locale : 'en-US'
        ],
      },
      {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: seoSchemas[brandName?.toLowerCase()]?.[
          brandName === 'Mailjet' ? locale : 'en-US'
        ].url,
      },
    ],
  })

export { getSeoSchema, logos, spamTrap, svgIcons }
