import React from 'react'
import { useTheme } from 'styled-components'
// Allow rendering dark version of button if prop isDark is true or if theme provided is the dark version
export const withDarkTheme =
  (Component) =>
  ({ isDark: isDarkProp, ...props }) => {
    const theme = useTheme()
    // Determine if isDark is true, whether it's from the prop or the theme
    const isDark = isDarkProp || theme.isDarkMode === true
    return <Component isDark={isDark} {...props} />
  }
