import styled, { css } from 'styled-components'
import { TextPart } from '@components/Banner/styles'
import ImageDiv from '@components/Image/styles/ImageDiv'
import ImageInline from '@styles/ImageInline'
import {
  Header2,
  Header3,
  Header4,
  Header5Bold,
  Header6,
} from '@typography'

// Apply spaces and style on Blog articles
export const ArticleRichTextDiv = styled.div`
  ${({ theme }) => css`
    & > ${Header2}:not(:last-child),
    & > ${Header3}:not(:last-child),
    & > ${Header4}:not(:last-child),
    & > ${Header5Bold}:not(:last-child),
    & > ${Header6}:not(:last-child),
    & > div:not(:last-child),
    & > ul:not(:last-child),
    & > ol:not(:last-child),
    & > p:not(:last-child) {
    padding-bottom: ${theme.sizes.s4};
    }

    & > ${Header2}:not(:first-child),
    & > ${Header3}:not(:first-child),
    & > ${Header4}:not(:first-child),
    & > ${Header5Bold}:not(:first-child),
    & > ${Header6}:not(:first-child) {
    margin-top: ${theme.sizes.s6};
    }

    ol, ul {
    margin: 0;
    }

    & > div:not(:first-child) {
    margin-bottom: ${theme.sizes.s6};
    margin-top: ${theme.sizes.s6};
    }

    & > *:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    }

    ${ImageDiv} {
    margin-left: auto;
    margin-right: auto;
    }

    ${ImageInline} * {
    height: auto;
    }

    ${TextPart} {
    width: auto;
    }
  `}
`