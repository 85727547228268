import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { map } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import GridLayout from '@components/GridLayout'
import { Header1, Header5Bold } from '@typography'
import { getOptionRichText, renderRichText } from '@utils'

import {
  ColumnsButton,
  ColumnsDiv,
  ColumnsIcon,
  UList,
} from './styles'

const optionsRichText = getOptionRichText()
optionsRichText.renderNode[BLOCKS.UL_LIST] = (_node, children) => (
  <UList>{children}</UList>
)

const Columns = ({ columns, isColumnsDataSection = false, ...rest }) => {
  const numberElement = columns.length
  const numberColumnsDesktop =
    [4, 7, 8].includes(numberElement) || numberElement > 9
      ? 4
      : numberElement === 2
      ? 2
      : 3

  return (
    <GridLayout
      desktop={numberColumnsDesktop}
      tablet={numberColumnsDesktop === 3 ? 3 : 2}
      {...rest}
    >
      {map(columns, (column) => {
        // if there's no button no need for another ColumnsDiv styling
        const hasButton = !!column?.button

        return (
          <div key={column.id}>
            <ColumnsDiv hasButton={hasButton}>
              {column.image && !isColumnsDataSection && (
                <ColumnsIcon
                  alt={column.image.alternative}
                  {...column.image.picture}
                />
              )}
              {isColumnsDataSection ? (
                <Header1 as='h2'>{column.title}</Header1>
              ) : (
                <Header5Bold>{column.title}</Header5Bold>
              )}
              <div>
                {renderRichText(column.paragraph, optionsRichText)}
              </div>
            </ColumnsDiv>
            {hasButton && (
              <ColumnsButton small to={column.button.link}>
                {column.button.label}
              </ColumnsButton>
            )}
          </div>
        )
      })}
    </GridLayout>
  )
}

Columns.propTypes = {
  columns: PropTypes.array,
  isColumnsDataSection: PropTypes.bool,
}

export default styled(Columns)``
