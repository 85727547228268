const { useEffect, useState } = require('react')

const useAnimationIntersectionObserver = (
  ref,
  option = { rootMargin: '0px', threshold: [0.33, 0.66] },
) => {
  const [isAnimating, setAnimated] = useState(true) // Todo: for SEO purpose we are removing use of animation in sections. Please use false as default value when we are back to animating things ;).

  const { rootMargin, threshold } = option

  const callback = (entries) => {
    if (!isAnimating && entries[0].isIntersecting) {
      setAnimated(true)
    }
  }

  const observer =
    typeof window !== 'undefined' &&
    new IntersectionObserver(callback, {
      root: ref.current,
      rootMargin,
      // Creates a threshold of with increments of 0.01
      threshold,
    })

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current)
    }
  })

  return [isAnimating, setAnimated]
}

export default useAnimationIntersectionObserver
