import styled, { css } from 'styled-components'
import { Container } from 'mailjet-react-components'

import Columns from '@components/Columns'
import ImageDiv from '@components/Image/styles/ImageDiv'
import { DivText } from '@components/ParagraphWithAny/styles'
import SectionTextImage from '@components/SectionTextImage'
import { BottomSectionChildren } from "@styles/BottomSectionChildren"
import {
  gridHelper,
  styleForDesktop,
  styleForMobile,
  styleForTablet,
} from '@utils'

export const SectionColumns = styled(SectionTextImage)`
  ${({
  isColumnCardSection = false,
  isTextRight,
  isTextTop,
  theme,
}) => css`

    ${Columns} {
      grid-column: 1 / 13;
      grid-row: 2;
    }

    ${Container} {
      row-gap: ${theme.sizes.s10};
    }

    ${DivText} {
      grid-column: ${isTextRight && '8 / 13'};
      grid-row: 1;
      margin-left: ${isTextRight ? theme.sizes.s10 : 0};
      margin-right: ${isTextRight || isTextTop ? 0 : theme.sizes.s10};
    }

    ${styleForDesktop} {
      ${BottomSectionChildren} {
        padding-top: ${theme.sizes.s9};
      }
    }

    ${styleForTablet} {
      ${BottomSectionChildren} {
        padding-top: ${theme.sizes.s6};
      }

      ${Container} {
        row-gap: ${theme.sizes.s8};

        ${ImageDiv} {
          grid-row: ${isTextTop ? 0 : 1};
        }

        ${DivText} {
          ${isTextRight && 'grid-column: 4 / 7'};
          grid-row: 1;
        }

        ${Columns} {
          grid-column: 1 / 7;
          grid-row: 2;
        }
      }
    }

    ${styleForMobile} {
      ${Container} {
        ${gridHelper(4, [4], [4], !isTextTop && [4] )}
        row-gap: ${!isColumnCardSection && theme.sizes.s6};

        ${ImageDiv} {
          grid-row: 1;
        }

        ${DivText} {
          grid-row: ${isTextTop ? 1 : 2};
          ${isTextRight && 'grid-column: 1 / 5'};
          margin: 0;
        }

        ${Columns} {
          grid-row: ${isTextTop ? 2 : 3};
          padding-top: ${theme.sizes.s3};
        }

        ${BottomSectionChildren} {
          grid-row: ${isTextTop ? 3 : 4};
        }
      }
    }
  `}
`
