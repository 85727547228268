import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { startsWith, trimEnd } from 'lodash'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import GlobalContext from '@components/Layout/GlobalContext'
import { sanitizeTrailingSlash } from '@utils'
import ArrowNextSVG from '@icons/ArrowNextSVG'
import ArrowPreviousSVG from '@icons/ArrowPreviousSVG'

export const getLinkStyle = ({
  isArrowNext,
  isArrowPrev,
  isDisplayedInline = true,
  isParagraphLink,
  theme,
  underline,
}) => css`
  display: ${isDisplayedInline ? 'inline-flex' : 'inline'};
  color: ${theme.colors.neutral100};

  ${(isArrowNext || isArrowPrev) &&
  `
    display: flex;
    align-items: center;

    & > * {
      color: ${theme.colors.secondary60};
    }

    & > ${ArrowNextSVG} path, & > ${ArrowPreviousSVG} path {
      fill: ${theme.colors.secondary60};
    }

    & > ${ArrowNextSVG} {
      margin: 0px 0px 0px ${theme.sizes.s3};
    }

    & > ${ArrowPreviousSVG} {
      margin: 0px ${theme.sizes.s3} 0px 0px;
    }
  `}

  ${(underline || isParagraphLink) &&
  `
  color: ${theme.colors.secondary60};
  * {
    color: ${theme.colors.secondary60};
  }

  & > svg path {
    fill: ${theme.colors.secondary60};
  }
    

  &:hover {
    color: ${theme.colors.secondary50};
    * {
      color: ${theme.colors.secondary50};
    }

    & > svg path {
      fill: ${theme.colors.secondary50};
    }
  }

  &:active {
    color: ${theme.colors.secondary70};
    * {
      color: ${theme.colors.secondary70};
    }

    & > svg path {
      fill: ${theme.colors.secondary70};
    }
  }

  &:focus {
    color: ${theme.colors.secondary60};
    * {
      color: ${theme.colors.secondary60};
    }

    & > svg path {
      fill: ${theme.colors.secondary60};
    }
  }
  `}

  &, & * {
    transition: color 0.25s;
    ${underline
      ? `
      text-decoration: underline;
      text-underline-offset: 3px;
      text-decoration-thickness: ${theme.sizes.s1};
      `
      : `text-decoration: none;`}
  }
`

const StyledLinkComponent = ({
  children,
  isArrowPrev,
  isArrowNext,
  ...props
}) => {
  return (
    <Link isArrowNext={isArrowNext} isArrowPrev={isArrowPrev} {...props}>
      {isArrowPrev && <ArrowPreviousSVG />}
      {children}
      {isArrowNext && <ArrowNextSVG />}
    </Link>
  )
}

export const StyledLink = styled(StyledLinkComponent).attrs(
  ({ shouldSanitize = true, to }) => {
    // For links targeting page anchor, like /page_link#form
    const isAnchorLink = to && to.includes('#')
    // For links using query params, like /page_link?param=value
    const isQueryParam = to && to.includes('?')
    if (isAnchorLink || isQueryParam) {
      to = trimEnd(to, '/')
    }

    return {
      // The link needs to be sanitized when internal
      to:
        to &&
        to.slice(0, 1) === '/' &&
        shouldSanitize &&
        !isAnchorLink &&
        !isQueryParam
          ? sanitizeTrailingSlash(to)
          : to,
    }
  },
)`
  ${getLinkStyle}
`
// External links opens in a new tab
// baseUrl
const replace = (link, baseUrl) => {
  if (startsWith(link, baseUrl)) {
    const href = link?.replace(baseUrl, '')
    return { href }
  } else {
    return { href: link }
  }
}
const links = {
  'https://www.mailgun.com': (x, y) => replace(x, y),
  'https://www.mailjet.com': (x, y) => replace(x, y),
}
// Todo: might try exporting from a different file, potentially cause issue with styled components imports
export const ExternalLink = styled.a.attrs(({ href }) => {
  const siteContext = useContext(GlobalContext)
  const { baseUrl } = siteContext.siteConfig

  const externalLinksProps = {
    target: '_tabs',
    rel: 'noopener noreferrer',
  }

  if (links[baseUrl]) {
    return {
      ...links[baseUrl](href, baseUrl),
      ...externalLinksProps,
    }
  }
  return externalLinksProps
})`
  ${getLinkStyle}
`

StyledLink.propTypes = {
  underline: PropTypes.bool,
  isParagraphLink: PropTypes.bool,
  shouldSanitize: PropTypes.bool,
  theme: PropTypes.object,
  to: PropTypes.string,
}

ExternalLink.propTypes = {
  underline: PropTypes.bool,
  isParagraphLink: PropTypes.bool,
  theme: PropTypes.object,
  to: PropTypes.string,
}
